import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromAuth from '@store/auth';
import { AuthService } from '@modules/auth';
import { Router } from '@angular/router';

@Injectable()
export class IsAuthorizedInterceptor implements HttpInterceptor {
  constructor(private store: Store<fromAuth.AuthState>, private auth: AuthService, private router: Router) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(errorData => {
        if (
          (errorData instanceof HttpErrorResponse && errorData.status === 401) ||
          (errorData instanceof HttpErrorResponse && errorData.status === 403)
        ) {
          this.store.dispatch(new fromAuth.SingInFail(errorData.message));
          this.store.dispatch(new fromAuth.SignOut());

          this.auth.clearStorageData();
          this.router.navigate(['login']);
        }

        return throwError(errorData);
      })
    );
  }
}
