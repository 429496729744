export enum ExerciseTypesEnum {
  E1A = 'E1A',
  E1B = 'E1B',
  E1BA = 'E1BA',
  E1BB = 'E1BB',
  E1BC = 'E1BC',
  E1BX = 'E1BX',
  E1C = 'E1C',
  E1D = 'E1D',
  E2A = 'E2A',
  E2AB = 'E2AB',
  E2B = 'E2B',
  E2C = 'E2C',
  E2CA = 'E2CA',
  E2CB = 'E2CB',
  E2CE = 'E2CE',
  E2D = 'E2D',
  E2E = 'E2E',
  E2F = 'E2F',
  E2G = 'E2G',
  E2H = 'E2H',
  E3A = 'E3A',
  E3B = 'E3B',
  E3D = 'E3D',
  E3F = 'E3F',
  E3E = 'E3E',
  E3G = 'E3G',
  E4A = 'E4A',
  T1A = 'T1A',
  T1AA = 'T1AA',
  T1AB = 'T1AB',
  T1B = 'T1B',
  T1D = 'T1D',
  T1E = 'T1E',
  T1F = 'T1F',
  T1G = 'T1G',
  BS1A = 'BS1A',
  BS1B = 'BS1B',
  GP1A = 'GP1A',
  GP1B = 'GP1B',
  M1A = 'M1A',
  EUPP1A = 'EUPP1A',
  ERUTA1A = 'ERUTA1A'
}
