<form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit($event)">
  <header class="login-form__header">
    <img class="login-form__logo" src="../../../../assets/images/{{this.logo}}logo.png" alt="tiki science logo" />
  </header>

  <mat-card class="login-form__controls">
    <div class="err-msg" *ngIf="errMsg$ | async as err">{{err}}</div>
    <input [class.invalid]="email.invalid && (email.dirty || email.touched)"
           class="login-form__input"
           type="email"
           formControlName="email"
           placeholder="Name" />

    <input [class.invalid]="password.invalid && (password.dirty || password.touched)"
           class="login-form__input"
           formControlName="password"
           type="password"
           placeholder="Password"
           required />

    <button type="submit" class="login-form__submit">LOGIN</button>
  </mat-card>
</form>

<div *ngIf="(loading$ | async)" class="loader"></div>
