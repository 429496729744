import { Action } from '@ngrx/store';

export const START_LOADING = '[General] Start Loading';
export const STOP_LOADING = '[General] Stop Loading';

export class StartLoading implements Action {
  readonly type = START_LOADING;
  constructor() {}
}

export class StopLoading implements Action {
  readonly type = STOP_LOADING;
  constructor() {}
}

export type LoadingAction = StartLoading | StopLoading;
