import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import { AuthState } from '@store/auth';
import * as fromStore from '@store/auth';
import { AuthService } from '../auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private store: Store<AuthState>, private auth: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(select(fromStore.isAuthenticated)).pipe(
      tap(isAuthenticated => {
        const isRedirect = route.queryParams.token;

        if ((!isRedirect && !isAuthenticated) || (!isRedirect && !this.auth.isAuthorized())) {
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      })
    );
  }
}
