<header class="header" >
  <div class="header__logo">
    <span routerLink="/home" class="logo_{{this.logo}}"></span>
  </div>

  <div class="dropdown-menu">
    <a [matMenuTriggerFor]="menu" class="ico ico-profile-{{this.logo}}"></a>
    <mat-menu #menu="matMenu" xPosition="before"
              [overlapTrigger]="false" class="user-menu">
      <button mat-menu-item (click)="signOut()" class="user-menu__item">
        <i class="ico-exit-app"></i>
        <span class="user-menu__item-text">Sign Out</span>
      </button>
    </mat-menu>
  </div>
</header>
