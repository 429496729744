import { createSelector } from '@ngrx/store';

import { getAuthState } from '../reducers';

export const isAuthenticated = createSelector(
  getAuthState,
  userInfo => userInfo.isAuthenticated
);

export const getErrMsg = createSelector(
  getAuthState,
  state => state.errMsg
);
