import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopBarModule } from './modules/common/top-bar';
import { AuthModule } from './modules/auth';
import { environment } from '../environments/environment';
import { loadingReducer } from '@store/general/reducer';
import { TokenInterceptor } from './interceptors';
import { RefreshTokenInterceptor } from './interceptors/refresh-token.interceptor';
import { IsAuthorizedInterceptor } from './interceptors/is-authorized.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TopBarModule,
    AuthModule,
    StoreModule.forRoot({ general: loadingReducer }),
    EffectsModule.forRoot([]),
    HttpClientModule,
    BrowserAnimationsModule,
    environment.target === 'production' ? [] : StoreDevtoolsModule.instrument(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IsAuthorizedInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
