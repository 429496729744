import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/modules/common/material/material.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthGuardService, PreviewGuardService, TokenAuthGuardService } from './guards';
import { SignInComponent } from './sign-in';
import { AuthService } from './auth.service';
import { authReducer, effects } from '@store/auth';

@NgModule({
  imports: [
    AuthRoutingModule,
    ReactiveFormsModule,
    StoreModule.forFeature('auth', authReducer),
    EffectsModule.forFeature(effects),
    CommonModule,
    MaterialModule,
  ],
  declarations: [SignInComponent],
  providers: [AuthGuardService, PreviewGuardService, TokenAuthGuardService, AuthService],
})
export class AuthModule {}
