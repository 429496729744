import { Component, ElementRef, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';

import * as fromStore from '@store/auth';
import { AuthState } from '@app/store/auth';
import { Branches } from '@app/enums';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'tiki-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public isSignedIn$: Observable<boolean>;
  backgroundColor: string;
  branchID: string;
  branchTitle: string;

  constructor(private store: Store<AuthState>, private el: ElementRef, private title: Title) {
    try {
      this.branchID = $ENV_OUTER.BRANCH_ID;
    } catch (error) {
      this.branchID = '5';
    }
    switch (this.branchID) {
      case Branches.TIKIEN:
        this.backgroundColor = '#cc0033';
        this.branchTitle = 'Tiki English ELEV';
        break;
      case Branches.TIKISO:
        this.backgroundColor = '#99cc33';
        this.branchTitle = 'Tiki SO ELEV';
        break;
      case Branches.TIKIMA:
        this.backgroundColor = '#66ccff';
        this.branchTitle = 'Tiki Matematik ELEV';
        break;
      case Branches.TIKIPL:
        this.backgroundColor = '#990099';
        this.branchTitle = 'Tiki Play ELEV';
        break;
      case Branches.TIKISE:
        this.backgroundColor = '#cfdb06';
        this.branchTitle = 'Tiki Svenska ELEV';
        break;
      default:
        this.backgroundColor = '#f2a948';
        this.branchTitle = 'Tiki NO ELEV';
        break;
    }

    this.isSignedIn$ = this.store.pipe(select(fromStore.isAuthenticated));
  }

  ngOnInit() {
    const style: string = `
     ::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: ${this.backgroundColor};
        border-radius: 10px;
      }
    `;
    this.title.setTitle(this.branchTitle);
    this.createStyle(style);
  }

  createStyle(style: string): void {
    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(style));
    this.el.nativeElement.appendChild(styleElement);
  }
}
