/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./top-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/material/menu";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/cdk/bidi";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i8 from "@angular/common";
import * as i9 from "./top-bar.component";
import * as i10 from "@ngrx/store";
var styles_TopBarComponent = [i0.styles];
var RenderType_TopBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopBarComponent, data: {} });
export { RenderType_TopBarComponent as RenderType_TopBarComponent };
export function View_TopBarComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "header", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "header__logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["routerLink", "/home"]], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 12, "div", [["class", "dropdown-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "a", [["aria-haspopup", "true"]], [[8, "className", 0], [1, "aria-expanded", 0]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 1196032, null, 0, i3.MatMenuTrigger, [i4.Overlay, i1.ElementRef, i1.ViewContainerRef, i3.MAT_MENU_SCROLL_STRATEGY, [2, i3.MatMenu], [8, null], [2, i5.Directionality], i6.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 9, "mat-menu", [["class", "user-menu"], ["xPosition", "before"]], null, null, null, i7.View_MatMenu_0, i7.RenderType_MatMenu)), i1.ɵdid(8, 1294336, [["menu", 4]], 2, i3.MatMenu, [i1.ElementRef, i1.NgZone, i3.MAT_MENU_DEFAULT_OPTIONS], { xPosition: [0, "xPosition"], overlapTrigger: [1, "overlapTrigger"], panelClass: [2, "panelClass"] }, null), i1.ɵqud(603979776, 1, { items: 1 }), i1.ɵqud(335544320, 2, { lazyContent: 0 }), i1.ɵprd(2048, null, i3.ɵf24, null, [i3.MatMenu]), (_l()(), i1.ɵeld(12, 0, null, 0, 4, "button", [["class", "user-menu__item mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 13)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.signOut() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i7.View_MatMenuItem_0, i7.RenderType_MatMenuItem)), i1.ɵdid(13, 180224, [[1, 4]], 0, i3.MatMenuItem, [i1.ElementRef, i8.DOCUMENT, i6.FocusMonitor, [2, i3.ɵf24]], null, null), (_l()(), i1.ɵeld(14, 0, null, 0, 0, "i", [["class", "ico-exit-app"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, 0, 1, "span", [["class", "user-menu__item-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sign Out"]))], function (_ck, _v) { var currVal_1 = "/home"; _ck(_v, 3, 0, currVal_1); var currVal_4 = i1.ɵnov(_v, 8); _ck(_v, 6, 0, currVal_4); var currVal_5 = "before"; var currVal_6 = false; var currVal_7 = "user-menu"; _ck(_v, 8, 0, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "logo_", _co.logo, ""); _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵinlineInterpolate(1, "ico ico-profile-", _co.logo, ""); var currVal_3 = (i1.ɵnov(_v, 6).menuOpen || null); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_8 = i1.ɵnov(_v, 13).role; var currVal_9 = i1.ɵnov(_v, 13)._highlighted; var currVal_10 = i1.ɵnov(_v, 13)._triggersSubmenu; var currVal_11 = i1.ɵnov(_v, 13)._getTabIndex(); var currVal_12 = i1.ɵnov(_v, 13).disabled.toString(); var currVal_13 = (i1.ɵnov(_v, 13).disabled || null); _ck(_v, 12, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); }); }
export function View_TopBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tiki-top-bar", [], [[4, "background-color", null]], null, null, View_TopBarComponent_0, RenderType_TopBarComponent)), i1.ɵdid(1, 49152, null, 0, i9.TopBarComponent, [i10.Store], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).bgColor; _ck(_v, 0, 0, currVal_0); }); }
var TopBarComponentNgFactory = i1.ɵccf("tiki-top-bar", i9.TopBarComponent, View_TopBarComponent_Host_0, {}, {}, []);
export { TopBarComponentNgFactory as TopBarComponentNgFactory };
