import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import * as fromStore from '@store/auth';
import * as fromGeneral from '@store/general';
import { AuthState } from '@app/store/auth';
import { Branches } from '@app/enums';

@Component({
  selector: 'tiki-login',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  public logo: string;
  public backgroundColor: string;
  public branchID: string;
  public loginForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });
  public errMsg$: Observable<string>;
  public loading$: Observable<boolean>;

  constructor(private store: Store<AuthState>) {
    this.errMsg$ = this.store.pipe(select(fromStore.getErrMsg));
    this.loading$ = this.store.pipe(select(fromGeneral.getLoading));

    this.store.dispatch(new fromStore.SignOut());

    try {
      this.branchID = $ENV_OUTER.BRANCH_ID;
    } catch (error) {
      this.branchID = '5';
    }
    switch (this.branchID) {
      case Branches.TIKIEN:
        this.logo = 'tikien-';
        this.backgroundColor = '#cc0033';
        break;
      case Branches.TIKISO:
        this.logo = 'tikiso-';
        this.backgroundColor = '#99cc33';
        break;
      case Branches.TIKIMA:
        this.logo = 'tikima-';
        this.backgroundColor = '#66ccff';
        break;
      case Branches.TIKIPL:
        this.logo = 'tikipl-';
        this.backgroundColor = '#990099';
        break;
      case Branches.TIKINO:
        this.logo = 'tikino-';
        this.backgroundColor = '#990099';
        break;
      case Branches.TIKISE:
        this.logo = 'tikise-';
        this.backgroundColor = '#cfdb06';
        break;
      default:
        this.logo = '';
        this.backgroundColor = '#f2a948';
        break;
    }
  }

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }

  public onSubmit(e: Event) {
    e.preventDefault();

    this.store.dispatch(new fromStore.SignIn(this.loginForm.value));
  }
}
