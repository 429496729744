import { Action } from '@ngrx/store';

import { ISignInParams } from '@modules/auth';

export const SIGN_IN = '[Auth] Sign In';
export const SIGN_IN_SUCCESS = '[Auth] Sign In Success';
export const SIGN_IN_FAIL = '[Auth] Sign In Fail';
export const SIGN_OUT = '[Auth] Sign Out';

export class SignIn implements Action {
  readonly type = SIGN_IN;
  constructor(readonly payload: ISignInParams) {}
}

export class SignInSuccess implements Action {
  readonly type = SIGN_IN_SUCCESS;
  constructor() {}
}

export class SignOut implements Action {
  readonly type = SIGN_OUT;
  constructor() {}
}

export class SingInFail implements Action {
  readonly type = SIGN_IN_FAIL;
  constructor(readonly payload: string) {}
}

export type AuthAction = SignIn | SignOut | SignInSuccess | SingInFail;
