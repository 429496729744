import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { AuthState } from '@store/auth';
import * as fromAuth from '@store/auth';
import { AuthService } from '../auth.service';

@Injectable()
export class PreviewGuardService implements CanActivate {
  constructor(private router: Router, private store: Store<AuthState>, private auth: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const token = route.queryParams.token;
    const moduleId = route.queryParams.module_id;
    if (token) {
      this.signInUser(token, moduleId);
    }

    return true;
  }

  private signInUser(token: string, moduleId?: number): void {
    this.auth.saveToken(token);
    this.store.dispatch(new fromAuth.SignInSuccess());
    if (moduleId) {
      this.router.navigate([`/module/${moduleId}`]);
    } else {
      this.router.navigate(['/home']);
    }
  }
}
