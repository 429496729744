import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopBarComponent } from './top-bar.component';
import { MaterialModule } from '@modules/common/material/material.module';
import { AppRoutingModule } from '@app/app-routing.module';

@NgModule({
  declarations: [TopBarComponent],
  exports: [TopBarComponent],
  imports: [CommonModule, AppRoutingModule, MaterialModule],
})
export class TopBarModule {}
