import { createFeatureSelector } from '@ngrx/store';

import * as fromActions from '../actions';

export interface LoadingState {
  loading: boolean;
}

export const initialState: LoadingState = {
  loading: false,
};

export function loadingReducer(state = initialState, action: fromActions.LoadingAction): LoadingState {
  switch (action.type) {
    case fromActions.START_LOADING: {
      return { ...state, loading: true };
    }

    case fromActions.STOP_LOADING: {
      return { ...state, loading: false };
    }
  }

  return state;
}

export const getGeneralState = createFeatureSelector<LoadingState>('general');
