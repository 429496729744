import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as authActions from '../actions';
import * as fromGeneral from '@store/general';
import { AuthService } from '@modules/auth/auth.service';
var AuthEffects = /** @class */ (function () {
    function AuthEffects(actions$, router, auth, store) {
        var _this = this;
        this.actions$ = actions$;
        this.router = router;
        this.auth = auth;
        this.store = store;
        this.signIn$ = this.actions$.pipe(ofType(authActions.SIGN_IN), switchMap(function (action) {
            _this.store.dispatch(new fromGeneral.StartLoading());
            return _this.auth.signIn(action.payload).pipe(switchMap(function (response) {
                _this.auth.saveToken(response.token);
                _this.router.navigate(['home']);
                return [new authActions.SignInSuccess(), new fromGeneral.StopLoading()];
            }), catchError(function (errorData) {
                var error = errorData instanceof HttpErrorResponse ? errorData.error.message : errorData;
                _this.store.dispatch(new fromGeneral.StopLoading());
                return of(new authActions.SingInFail(error));
            }));
        }));
        this.signOut$ = this.actions$.pipe(ofType(authActions.SIGN_OUT), switchMap(function (_) {
            _this.auth.clearStorageData();
            _this.router.navigate(['login']);
            return [];
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "signIn$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "signOut$", void 0);
    return AuthEffects;
}());
export { AuthEffects };
