import * as tslib_1 from "tslib";
import { createFeatureSelector } from '@ngrx/store';
import * as fromActions from '../actions';
export var initialState = {
    isAuthenticated: false,
    errMsg: '',
};
export function authReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case fromActions.SIGN_IN_SUCCESS: {
            return tslib_1.__assign({}, state, { isAuthenticated: true, errMsg: '' });
        }
        case fromActions.SIGN_OUT: {
            return tslib_1.__assign({}, state, { isAuthenticated: false, errMsg: '' });
        }
        case fromActions.SIGN_IN_FAIL: {
            return tslib_1.__assign({}, state, { isAuthenticated: false, errMsg: action.payload });
        }
    }
    return state;
}
export var getAuthState = createFeatureSelector('auth');
