import { createFeatureSelector } from '@ngrx/store';

import * as fromActions from '../actions';

export interface AuthState {
  isAuthenticated: boolean;
  errMsg: string;
}

export const initialState: AuthState = {
  isAuthenticated: false,
  errMsg: '',
};

export function authReducer(state = initialState, action: fromActions.AuthAction): AuthState {
  switch (action.type) {
    case fromActions.SIGN_IN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        errMsg: '',
      };
    }

    case fromActions.SIGN_OUT: {
      return {
        ...state,
        isAuthenticated: false,
        errMsg: '',
      };
    }

    case fromActions.SIGN_IN_FAIL: {
      return {
        ...state,
        isAuthenticated: false,
        errMsg: action.payload,
      };
    }
  }

  return state;
}

export const getAuthState = createFeatureSelector<AuthState>('auth');
