import { NgModule } from '@angular/core';
import * as materialModule from '@angular/material';

const matModules = [materialModule.MatCardModule, materialModule.MatDialogModule, materialModule.MatMenuModule];

@NgModule({
  imports: [...matModules],
  exports: [...matModules],
})
export class MaterialModule {}
