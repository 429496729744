import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { TOKEN_STORAGE_KEY, LOGIN_ENDPOINT } from './auth.constants';
import { ISignInParams, ISignInResponse } from '@modules/auth';
import { AuthState } from '@app/store/auth/reducers';
import { SignInSuccess } from '@app/store/auth/actions';

import { environment } from '@environments/environment';

@Injectable()
export class AuthService {
  private loginEndpoint = LOGIN_ENDPOINT;

  constructor(private http: HttpClient, private store: Store<AuthState>) {
    const token = this.getToken();

    if (token) {
      this.store.dispatch(new SignInSuccess());
    }
  }

  public signIn(params: ISignInParams): Observable<ISignInResponse> {
    return this.http.post<ISignInResponse>(this.loginEndpoint, {
      ...params,
      branchId: environment.branchID,
    });
  }

  public isAuthorized(): boolean {
    return !!this.getToken();
  }

  public saveToken(token: string): void {
    localStorage.setItem(TOKEN_STORAGE_KEY, token);
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_STORAGE_KEY);
  }

  public clearStorageData(): void {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
  }
}
