import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { AuthState } from '@store/auth';
import * as fromAuth from '@store/auth';
import { AuthService } from '../auth.service';

@Injectable()
export class TokenAuthGuardService implements CanActivate {
  constructor(private router: Router, private store: Store<AuthState>, private auth: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const token = route.queryParams.access_token;
    if (token) {
      this.signInUser(token);
    }
    return true;
  }

  private signInUser(token: string): void {
    this.auth.saveToken(token);
    this.store.dispatch(new fromAuth.SignInSuccess());
    this.router.navigate(['/home']);
  }
}
