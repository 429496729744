import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';

import * as authActions from '../actions';
import * as fromGeneral from '@store/general';
import { AuthService } from '@modules/auth/auth.service';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private auth: AuthService,
    private store: Store<fromGeneral.LoadingState>
  ) {}

  @Effect()
  signIn$ = this.actions$.pipe(
    ofType(authActions.SIGN_IN),
    switchMap((action: authActions.SignIn) => {
      this.store.dispatch(new fromGeneral.StartLoading());

      return this.auth.signIn(action.payload).pipe(
        switchMap(response => {
          this.auth.saveToken(response.token);
          this.router.navigate(['home']);
          return [new authActions.SignInSuccess(), new fromGeneral.StopLoading()];
        }),
        catchError(errorData => {
          const error = errorData instanceof HttpErrorResponse ? errorData.error.message : errorData;

          this.store.dispatch(new fromGeneral.StopLoading());
          return of(new authActions.SingInFail(error));
        })
      );
    })
  );

  @Effect()
  signOut$ = this.actions$.pipe(
    ofType(authActions.SIGN_OUT),
    switchMap(_ => {
      this.auth.clearStorageData();
      this.router.navigate(['login']);
      return [];
    })
  );
}
