import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import * as fromStore from '@store/auth';
import * as fromGeneral from '@store/general';
import { Branches } from '@app/enums';
var SignInComponent = /** @class */ (function () {
    function SignInComponent(store) {
        this.store = store;
        this.loginForm = new FormGroup({
            email: new FormControl('', [Validators.required]),
            password: new FormControl('', [Validators.required]),
        });
        this.errMsg$ = this.store.pipe(select(fromStore.getErrMsg));
        this.loading$ = this.store.pipe(select(fromGeneral.getLoading));
        this.store.dispatch(new fromStore.SignOut());
        try {
            this.branchID = $ENV_OUTER.BRANCH_ID;
        }
        catch (error) {
            this.branchID = '5';
        }
        switch (this.branchID) {
            case Branches.TIKIEN:
                this.logo = 'tikien-';
                this.backgroundColor = '#cc0033';
                break;
            case Branches.TIKISO:
                this.logo = 'tikiso-';
                this.backgroundColor = '#99cc33';
                break;
            case Branches.TIKIMA:
                this.logo = 'tikima-';
                this.backgroundColor = '#66ccff';
                break;
            case Branches.TIKIPL:
                this.logo = 'tikipl-';
                this.backgroundColor = '#990099';
                break;
            case Branches.TIKINO:
                this.logo = 'tikino-';
                this.backgroundColor = '#990099';
                break;
            case Branches.TIKISE:
                this.logo = 'tikise-';
                this.backgroundColor = '#cfdb06';
                break;
            default:
                this.logo = '';
                this.backgroundColor = '#f2a948';
                break;
        }
    }
    Object.defineProperty(SignInComponent.prototype, "email", {
        get: function () {
            return this.loginForm.get('email');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SignInComponent.prototype, "password", {
        get: function () {
            return this.loginForm.get('password');
        },
        enumerable: true,
        configurable: true
    });
    SignInComponent.prototype.onSubmit = function (e) {
        e.preventDefault();
        this.store.dispatch(new fromStore.SignIn(this.loginForm.value));
    };
    return SignInComponent;
}());
export { SignInComponent };
