import * as tslib_1 from "tslib";
import { createFeatureSelector } from '@ngrx/store';
import * as fromActions from '../actions';
export var initialState = {
    loading: false,
};
export function loadingReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case fromActions.START_LOADING: {
            return tslib_1.__assign({}, state, { loading: true });
        }
        case fromActions.STOP_LOADING: {
            return tslib_1.__assign({}, state, { loading: false });
        }
    }
    return state;
}
export var getGeneralState = createFeatureSelector('general');
