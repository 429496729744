import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { TOKEN_STORAGE_KEY, LOGIN_ENDPOINT } from './auth.constants';
import { SignInSuccess } from '@app/store/auth/actions';
import { environment } from '@environments/environment';
var AuthService = /** @class */ (function () {
    function AuthService(http, store) {
        this.http = http;
        this.store = store;
        this.loginEndpoint = LOGIN_ENDPOINT;
        var token = this.getToken();
        if (token) {
            this.store.dispatch(new SignInSuccess());
        }
    }
    AuthService.prototype.signIn = function (params) {
        return this.http.post(this.loginEndpoint, tslib_1.__assign({}, params, { branchId: environment.branchID }));
    };
    AuthService.prototype.isAuthorized = function () {
        return !!this.getToken();
    };
    AuthService.prototype.saveToken = function (token) {
        localStorage.setItem(TOKEN_STORAGE_KEY, token);
    };
    AuthService.prototype.getToken = function () {
        return localStorage.getItem(TOKEN_STORAGE_KEY);
    };
    AuthService.prototype.clearStorageData = function () {
        localStorage.removeItem(TOKEN_STORAGE_KEY);
    };
    return AuthService;
}());
export { AuthService };
